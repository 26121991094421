import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {Observable, Subject, takeUntil} from "rxjs";
import {Project} from "@core/services/backend/common/interface/projects";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GroupsService} from "@core/services/backend/common/service/groups.service";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {Group} from "@core/services/backend/common/interface/groups";
import {
    GroupsCreateUpdateComponent
} from "@app/pages/administration/groups-create-update/groups-create-update.component";
import {User} from "@core/services/backend/common/interface/users";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/operators";
import {UsersService} from "@core/services/backend/common/service/users.service";

@Component({
    selector: 'vex-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class GroupsComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    groupList: Group[] = [];
    dataSource: MatTableDataSource<Group> | null;
    searchForm: FormGroup;
    usersList: User[] = [];
    userSelected: 0;
    filteredUserList: Observable<User[]>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    columns: TableColumn<Group>[] = [
        {label: 'GRIDS.ID', property: 'id', type: 'text', visible: false},
        {label: 'GRIDS.NOMBRE_GRUPO', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'GRIDS.GRUPO_USUARIOS', property: 'users', type: 'image', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    currentPage = 0;
    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    isLoading: boolean;

    constructor(private groups: GroupsService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private userService: UsersService,
    ) {
    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource();

        this.searchForm = new FormGroup({
            user: new FormControl(),
            group: new FormControl()
        })
        this.loadData()

    }

    loadData() {
        const that = this
        this.initializeData().then(() => {
            this.dataSource.data = that.groupList;

            const list = that.userService.list('', 0, 100000, 'last_Name', true);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.usersList = data.data;
                that.usersList.forEach(user => {
                    user.name = user.lastName + ' ' + user.firstName;
                })

                that.filteredUserList = this.searchForm.get('user')!.valueChanges.pipe(
                    startWith(''),
                    map(value => {
                        const name = typeof value === 'string' ? value : value?.name;
                        return name ? that._filterUser(name as string) : that.usersList.slice();
                    }),
                );

            });
        });
    }

    private getSearch() {

        this.userSelected = this.searchForm.get("user").value ?  this.searchForm.get("user").value.ID : 0;
        return {
            name: this.searchForm.get("group").value ? '%' + this.searchForm.get("group").value.toString().toUpperCase() + '%' : '',
            userId: this.searchForm.get("user").value ?  this.searchForm.get("user").value.ID : 0
        }
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.groups.list(JSON.stringify(that.getSearch()), that.currentPage, that.pageSize, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.groupList = data.data;
                that.paginator.pageIndex = that.currentPage;
                // @ts-ignore
                that.paginator.length = data.total_records;
                resolve();
            });
        });
    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    buscar() {
        this.loadData();
    }

    createGroup() {
        this.dialog.open(GroupsCreateUpdateComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        } ).afterClosed().subscribe((project: Project) => {
            this.loadData();
        });
    }

    updateGroup(group: Group) {
        this.dialog.open(GroupsCreateUpdateComponent, {
            data: group,
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.loadData();
        });
    }

    deleteGroup(project: Project) {
        const that = this;
        this.groups.delete(project).subscribe({
            next(result) {
                that.handleSuccessResponse();
                that.loadData();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.GRUPOS.DELETE, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.GRUPOS.DELETE_ERROR, '', {
                duration: 3000
            });

        });
    }

    private _filterUser(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.usersList.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    displayUserFn(user: User) {
        if (user) {
            return user.name;
        }
    }

    onSelectionUserChange(event: MatAutocompleteSelectedEvent) {
        //console.log('onSelectionChange called', event.option.value);
    }

    resetUser() {
        this.userSelected = 0;
        this.searchForm.reset();
        this.loadData();
    }


}
