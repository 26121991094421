import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {Group} from "@core/services/backend/common/interface/groups";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {Link} from "@core/services/backend/common/interface/links";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkedHoursService} from "@core/services/backend/common/service/workedHours.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "@core/guard/auth-service";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {endOfWeek, format, parseISO, startOfWeek} from 'date-fns';

@Component({
    selector: 'vex-activity-assigment-pending',
    templateUrl: './activity-assigment-pending.component.html',
    styleUrls: ['./activity-assigment-pending.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ActivityAssigmentPendingComponent implements OnInit {

    origin;
    dataSource: MatTableDataSource<Group> | null;

    columns: TableColumn<Link>[] = [
        {label: 'GRIDS.FECHA_INICIO', property: 'weekStart', type: 'text', visible: true},
        {label: 'GRIDS.FECHA_TERMINO', property: 'weekEnd', type: 'text', visible: true},
        {label: 'GRIDS.CANTIDAD', property: 'count', type: 'text', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    statusList = ["PENDIENTE", "RECHAZADA", "ACEPTADA"]


    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<ActivityAssigmentPendingComponent>,
                private fb: FormBuilder,
                private workedHoursService: WorkedHoursService,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private authService: AuthService) {

        this.origin = defaults.pendingHours;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource();
        let datalist = []
        this.origin.forEach(week => {
            datalist.push({
                weekStart: format(this.toUTC(week.startWeek), "dd-MM-yyyy"),
                weekEnd: format(this.toUTC(week.endWeek), "dd-MM-yyyy"),
                count: week.count,
                startDate: week.startWeek,
                endDate: week.endWeek
            });
        });
        this.dataSource.data = [...datalist];
    }

    toUTC(date) {
        var tmp = new Date(date)
        return new Date(
            tmp.getUTCFullYear(),
            tmp.getUTCMonth(),
            tmp.getUTCDate(),
            tmp.getUTCHours(),
            tmp.getUTCMinutes(),
            tmp.getUTCSeconds())
    }


    groupDatesByWeek(dates: string[]): { weekStart: Date, weekEnd: Date, count: number }[] {
        const weeksMap = new Map<string, { weekStart: Date, weekEnd: Date, count: number }>();

        dates.forEach(dateStr => {
            let date = parseISO(dateStr);
            const day = 60 * 60 * 24 * 1000;
            date = new Date(date.getDate() + day);
            const weekStart = startOfWeek(date, {weekStartsOn: 1}); // Assuming week starts on Monday
            const weekEnd = endOfWeek(date, {weekStartsOn: 1});
            const weekKey = `${format(weekStart, 'yyyy-MM-dd')}_${format(weekEnd, 'yyyy-MM-dd')}`;

            if (!weeksMap.has(weekKey)) {
                weeksMap.set(weekKey, {weekStart, weekEnd, count: 0});
            }

            weeksMap.get(weekKey)!.count++;
        });

        return Array.from(weeksMap.values());
    }


    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    select(row) {
        this.dialogRef.close(row);
    }

}

