<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.BD_HH" | translate }}</h1>
                <vex-breadcrumbs
                        [crumbs]="['MENUS.GESTION_DE_ACTIVIDADES', 'MENUS.BD_HH']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content class="-mt-6">

        <div>
            <form [formGroup]="searchForm">

                <div class="bg-app-bar px-6 h-16 left-0 flex items-center" *ngIf="this.IS_SUPER_ADMIN">
                    <span class="flex-1"></span>

                    <span [matBadge]="dataSourceNotResponsible.data.length"
                          matBadgeOverlap="false">{{ 'LABELS.USERS_WITHOUT_RESPONSIBLE' | translate }}</span>
                </div>
                <div class="bg-app-bar px-6 h-16 left-0 flex items-center">

                    <div
                            class="bg-foreground rounded-full px-4 h-10 max-w-[250px] flex-auto flex items-center border border-gray-300">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:person"></mat-icon>
                        <input formControlName="user"
                               class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                               [placeholder]="'ACTIONS.BUSCAR_USUARIOS' | translate"
                               type="search">
                    </div>

                    <div
                            class="bg-foreground rounded-full px-4 h-10 max-w-[260px] flex-auto flex items-center border border-gray-300">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate formControlName="startDate"
                                   placeholder="{{ 'LABELS.FECHA_INICIO' | translate }}">
                            <input matEndDate formControlName="endDate"
                                   placeholder="{{ 'LABELS.FECHA_FIN' | translate }}" (dateChange)="dateSelected()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker maxRange=120 #picker></mat-date-range-picker>
                    </div>

                    <div
                            class="bg-foreground rounded-full px-4 h-10 max-w-[250px] flex-auto flex items-center border border-gray-300">
                        <mat-select formControlName="subArea"
                                    placeholder="{{ 'GRIDS.SELECCIONE_SUB_AREA' | translate }}">
                            <mat-option *ngFor="let subarea of subAreasList" [value]="subarea.ID">
                                {{ subarea.name }}
                            </mat-option>
                        </mat-select>
                    </div>

                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.BUSCAR_USUARIOS' | translate "
                            type="button"
                            (click)="buscar()">
                        <mat-icon svgIcon="mat:search"></mat-icon>
                    </button>

                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.RESET' | translate "
                            type="button"
                            (click)="reset()">
                        <mat-icon svgIcon="mat:sync"></mat-icon>
                    </button>

                    <span class="flex-1"></span>

                    <!--
                                        <button
                                                class="ml-4 flex-none"
                                                color="primary"
                                                mat-mini-fab
                                                [matTooltip]="'ACTIONS.VER_TODOS' | translate "
                                                type="button"
                                                (click)="verTodos()">
                                            <mat-icon svgIcon="mat:visibility"></mat-icon>
                                        </button>

                    -->
                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.HORAS_PENDIENTES' | translate "
                            type="button"
                            (click)="pendingSelected()">
                        <mat-icon svgIcon="mat:move_to_inbox"></mat-icon>
                    </button>

                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.COMENTAR_SELECCIONADAS' | translate "
                            type="button"
                            (click)="comentarSelected()">
                        <mat-icon svgIcon="mat:comment"></mat-icon>
                    </button>

                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.EXPORTAR' | translate "
                            type="button"
                            (click)="export()">
                        <mat-icon svgIcon="mat:cloud_download"></mat-icon>
                    </button>

                </div>

                <div class="bg-app-bar px-6 h-16 border-b left-0 flex items-center">

                    <div
                            class="bg-foreground rounded-full px-4 h-10 max-w-[500px] flex-auto flex items-center border border-gray-300">
                        <input type="text"
                               style="width:100% !important"
                               aria-label="Number"
                               matInput
                               formControlName="project"
                               placeholder="{{ 'PROYECTO.SELECCIONAR' | translate }}"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProjectFn"
                                          (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let option of filteredProjectList | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>

                        <button type="button" *ngIf="searchForm.get('project').value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="reset() ">
                            <mat-icon>close</mat-icon>
                        </button>

                    </div>

                    <div class="bg-foreground rounded-full px-4 h-10 max-w-[250px] flex-auto flex items-center border border-gray-300">
                        <mat-select formControlName="estado"
                                    placeholder="{{ 'GRIDS.SELECCIONE_ESTADO' | translate }}">
                            <mat-option *ngFor="let estado of estadoList" [value]="estado">
                                {{ estado }}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="bg-foreground rounded-full px-4 h-10 max-w-[250px] flex-auto flex items-center border border-gray-300">
                        <mat-select formControlName="tipohh"
                                    placeholder="{{ 'GRIDS.SELECCIONE_TIPO_HH' | translate }}">
                            <mat-optgroup *ngFor="let group of tipoHHList" [label]="group.name">
                                <mat-option *ngFor="let option of group.group" [value]="option.key">
                                    {{ option.value }}
                                </mat-option>
                            </mat-optgroup>

                        </mat-select>
                    </div>

                    <span class="flex-1"></span>


                    <button
                            class="ml-4 flex-none "
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.EXPAND' | translate "
                            type="button"
                            (click)="expandAll()">
                        <mat-icon svgIcon="mat:arrow_downward"></mat-icon>
                    </button>

                    <button
                            class="ml-4 flex-none"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.COLLAPSE' | translate "
                            type="button"
                            (click)="collapseAll()">
                        <mat-icon svgIcon="mat:arrow_upward"></mat-icon>
                    </button>


                    <button
                            class="ml-4 flex-none"
                            color="warn"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.RECHAZAR_SELECCIONADAS' | translate "
                            type="button"
                            [disabled]="selection.selected.length === 0 || notSelection"
                            (click)="rechazarSelected()">
                        <mat-icon svgIcon="mat:clear"></mat-icon>
                    </button>

                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.APROBAR_SELECCIONADAS' | translate "
                            type="button"
                            [disabled]="selection.selected.length === 0 || notSelection"
                            (click)="aprobarSelected()">
                        <mat-icon svgIcon="mat:check"></mat-icon>
                    </button>

                </div>
            </form>

            <div>
                <table class="table table table-striped w-full border-b-8">
                    <tr class="bg-app-bar">
                        <th style="text-align: center">{{ "LABELS.PENDIENTES" | translate }}</th>
                        <th style="text-align: center">{{ "LABELS.RECHAZADAS" | translate }}</th>
                        <th style="text-align: center">{{ "LABELS.ACEPTADAS" | translate }}</th>
                        <th style="text-align: center">{{ "LABELS.TOTAL_HH" | translate }}</th>
                    </tr>
                    <tr>
                        <td style="text-align: center">{{ pendientes }}/{{ pendientesTotal }}</td>
                        <td style="text-align: center">{{ rechazadas }}</td>
                        <td style="text-align: center">{{ aceptadas }}</td>
                        <td style="text-align: center">{{ total_hh }}</td>
                    </tr>
                </table>
            </div>

            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
                    <ng-container *ngIf="column.type === 'text'" matColumnDef="{{ column.field }}">
                        <mat-header-cell *matHeaderCellDef>{{ column.label | translate }}
                        </mat-header-cell>
                        <mat-cell class="fa-pull-right" *matCellDef="let row">
                            <div *ngIf="row.count !== 0 || row.extras !== 0">
                                {{ row[column.field] }}
                            </div>
                        </mat-cell>

                    </ng-container>
                    <ng-container *ngIf="column.type === 'checkbox'" matColumnDef="selected">
                        <mat-header-cell *matHeaderCellDef mat-header-cell>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                                          color="primary">
                            </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell class="fa-pull-right" *matCellDef="let row">
                            <mat-checkbox
                                    *ngIf="row.count !== 0 || row.extras !== 0"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    color="primary">
                            </mat-checkbox>

                            <button mat-mini-fab color="primary" class="ml-3 mr-3"
                                    (click)="verComentarios(row)"
                                    *ngIf="row.comments !== '' || row.statusComment != ''">
                                <mat-icon svgIcon="mat:comment"></mat-icon>
                            </button>


                        </mat-cell>
                    </ng-container>

                </ng-container>

                <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <!-- Group header -->
                <ng-container matColumnDef="groupHeader">
                    <mat-cell colspan="999" *matCellDef="let group"
                              [ngClass]="group.totalHours < 40 ? 'is-danger' : group.totalHours === 40 ? 'is-ok' : 'is-warn' ">
                        <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                        <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>

                        <div style="float: left; width: 37%">
                            {{ group[groupByColumns[group.level - 1]] }}
                        </div>

                        <div style="float: left;width: 15% ; text-align: center">
                            {{ group.totalHours }}
                        </div>
                        <div style="float: left;width: 12%; ; text-align: center">
                            {{ group.totalExtras }}
                            <button mat-mini-fab color="primary" class="ml-3" *ngIf="group.totalExtras > 0">
                                <mat-icon svgIcon="mat:warning"></mat-icon>
                            </button>
                        </div>
                        <div style="float: left;width: 10%;white-space: nowrap">
                            {{ group.subArea }} &nbsp;&nbsp;

                            <mat-checkbox (change)="$event ? groupToggle(group) : null"
                                          color="primary">
                            </mat-checkbox>
                        </div>
                        <div style="float: left;width: 10%">

                        </div>


                    </mat-cell>
                </ng-container>

                <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                         (click)="groupHeaderClick(row)"></mat-row>

            </mat-table>


        </div>

    </vex-page-layout-content>

</vex-page-layout>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-project="project" matMenuContent>
        <button mat-menu-item (click)="Comments(project)">
            <mat-icon svgIcon="mat:comment"></mat-icon>
            <span>{{ 'ACTIONS.COMMENTS' | translate }}</span>
        </button>
    </ng-template>
</mat-menu>
