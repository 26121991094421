import {Component, OnInit, ViewChild} from '@angular/core';
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {Observable, Subject, takeUntil} from "rxjs";
import {Link} from "@core/services/backend/common/interface/links";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {LinksService} from "@core/services/backend/common/service/links.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
    ExternalLinksCreateUpdateComponent
} from "@app/pages/administration/external-links-create-update/external-links-create-update.component";
import {Project} from "@core/services/backend/common/interface/projects";
import {AutoHoursService} from "@core/services/backend/common/service/autoHours.service";
import {AutoHours} from "@core/services/backend/common/interface/autoHours";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {User} from "@core/services/backend/common/interface/users";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/operators";
import {
    HoursAutoCreateUpdateComponent
} from "@app/pages/administration/hours-auto-create-update/hours-auto-create-update.component";

@Component({
    selector: 'vex-hours-auto',
    templateUrl: './hours-auto.component.html',
    styleUrls: ['./hours-auto.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class HoursAutoComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    mode: 'update' | 'delete' = 'update';
    autoHoursList: AutoHours[] = [];
    usersList: User[] = [];
    filteredUserList: Observable<User[]>;
    dataSource: MatTableDataSource<AutoHours> | null;
    searchForm: FormGroup;
    titleForm: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    columns: TableColumn<Link>[] = [
        {label: 'GRIDS.ID', property: 'id', type: 'text', visible: false},
        {label: 'GRIDS.USUARIO', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'GRIDS.LUNES', property: 'lunes', type: 'text', visible: true},
        {label: 'GRIDS.MARTES', property: 'martes', type: 'text', visible: true},
        {label: 'GRIDS.MIERCOLES', property: 'miercoles', type: 'text', visible: true},
        {label: 'GRIDS.JUEVES', property: 'jueves', type: 'text', visible: true},
        {label: 'GRIDS.VIERNES', property: 'viernes', type: 'text', visible: true},
        {label: 'GRIDS.SABADO', property: 'sabado', type: 'text', visible: true},
        {label: 'GRIDS.DOMINGO', property: 'domingo', type: 'text', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    currentPage = 0;
    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    isLoading: boolean;

    constructor(
        private autoService: AutoHoursService,
        private userService: UsersService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private snackbar: MatSnackBar
    ) {
    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource();

        this.searchForm = new FormGroup({
            user: new FormControl()
        })

        this.loadData()

    }

    loadData() {
        const that = this
        this.initializeData().then(() => {
            this.dataSource.data = that.autoHoursList;
        })
    }

    private getSearch() {
        return {
            userID: this.searchForm.get("user").value !== null ? this.searchForm.get("user").value.ID : 0,
        }
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {

            const list = that.userService.list('', 0, 100000, 'last_Name', true);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.usersList = data.data;
                that.usersList.forEach(user => {
                    user.name = user.lastName + ' ' + user.firstName;
                })

                that.filteredUserList = this.searchForm.get('user')!.valueChanges.pipe(
                    startWith(''),
                    map(value => {
                        const name = typeof value === 'string' ? value : value?.name;
                        return name ? this._filterUser(name as string) : that.usersList.slice();
                    }),
                );

                const list = this.autoService.list(JSON.stringify(that.getSearch()), that.currentPage, that.pageSize, '');
                list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

                    // @ts-ignore
                    that.autoHoursList = data.data;

                    that.autoHoursList.forEach((item, index) => {

                        // @ts-ignore
                        let user = that.usersList.filter(user => user.ID === item.user_id)[0]
                        // @ts-ignore
                        item.name = user.firstName + ' ' + user.lastName;

                        let days = item.days.split(',')
                        // @ts-ignore
                        item.lunes = days[0];
                        // @ts-ignore
                        item.martes = days[1];
                        // @ts-ignore
                        item.miercoles = days[2];
                        // @ts-ignore
                        item.jueves = days[3];
                        // @ts-ignore
                        item.viernes = days[4];
                        // @ts-ignore
                        item.sabado = days[5];
                        // @ts-ignore
                        item.domingo = days[6];
                    });

                    that.paginator.pageIndex = that.currentPage;
                    // @ts-ignore
                    that.paginator.length = data.total_records;
                    resolve();
                });
            });
        });
    }
    private _filterUser(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.usersList.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    displayUserFn(user: User) {
        if (user) {
            return user.name;
        }
    }

    onSelectionUserChange(event: MatAutocompleteSelectedEvent) {
        //console.log('onSelectionChange called', event.option.value);
    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    buscar() {
        this.loadData();
    }

    create() {
        this.dialog.open(HoursAutoCreateUpdateComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe((project: Project) => {
            this.loadData();
        });
    }

    updateProject(project: Project) {
        this.dialog.open(HoursAutoCreateUpdateComponent, {
            data: project,
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.loadData();
        });
    }

    deleteProject(project: Project) {
        const that = this;
        this.autoService.delete(project).subscribe({
            next(result) {
                this.mode = 'delete';
                that.handleSuccessResponse();
                that.loadData();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }

    isDeleteMode() {
        return this.mode === 'delete';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isUpdateMode() ? res.LINKS.UPDATE : res.LINKS.DELETE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isUpdateMode() ? res.LINKS.UPDATE_ERROR : res.LINKS.DELETE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }


    reset() {
    }

    resetUser() {
        this.searchForm.reset();
        this.loadData();
    }

    agregar() {

    }
}
