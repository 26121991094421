import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {FormControl, FormGroup, UntypedFormBuilder} from "@angular/forms";
import {User} from "@core/services/backend/common/interface/users";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {GroupsService} from "@core/services/backend/common/service/groups.service";
import {map, startWith} from "rxjs/operators";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Project} from "@core/services/backend/common/interface/projects";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {AutoHoursService} from "@core/services/backend/common/service/autoHours.service";

@Component({
    selector: 'vex-hours-auto-create-update',
    templateUrl: './hours-auto-create-update.component.html',
    styleUrls: ['./hours-auto-create-update.component.scss']
})
export class HoursAutoCreateUpdateComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();
    static id = 100;

    form: FormGroup;
    mode: 'create' | 'update' = 'create';
    userList: User[] = [];
    projectList: Project[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    usersCtrl = new FormControl('');
    selectedUsers: User[] = [];
    filteredUsers: Observable<any[]>;
    filteredProjectList: Observable<Project[]>;
    filteredUserList: Observable<User[]>;

    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;


    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<HoursAutoCreateUpdateComponent>,
                private fb: UntypedFormBuilder,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private users: UsersService,
                private autoService: AutoHoursService,
                private projects: ProjectsService) {

    }

    ngOnInit() {
        if (this.defaults) {
            this.mode = 'update';
        } else {
            this.defaults = {} as User;
        }
        this.initializeData().then(() => {
            this.filteredUsers = this.usersCtrl.valueChanges.pipe(
                startWith<string | any>(''),
                //map(value => typeof value === 'string' ? value : value.firstName + ' ' + value.lastName),
                map(user => (user ? this._filter(user) : this.userList.slice())),
            );
        })

        this.form = this.fb.group({
            id: [HoursAutoCreateUpdateComponent.id++],
            name:  [this.defaults.name || ''],
            project: {},
            user: {},
            active: [this.defaults.active || false],
            lunes: [this.defaults.lunes || 0],
            martes: [this.defaults.martes || 0],
            miercoles: [this.defaults.miercoles || 0],
            jueves: [this.defaults.jueves || 0],
            viernes: [this.defaults.viernes || 0],
            sabado: [this.defaults.sabado || 0],
            domingo: [this.defaults.domingo || 0],
        });


    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.users.list('', 0, 100000, '', false);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.userList = data.data.filter(user => user.notRegisterHours)
                that.userList.forEach(user => {
                    user.name = user.lastName + ' ' + user.firstName;
                })

                that.filteredUserList = this.form.get('user')!.valueChanges.pipe(
                    startWith(''),
                    map(value => {
                        const name = typeof value === 'string' ? value : value?.name;
                        return name ? this._filterUser(name as string) : that.userList.slice();
                    }),
                );

                const list = this.projects.list('', 0, 100000, '', false);
                list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                    // @ts-ignore
                    that.projectList = data.data
                    that.projectList.forEach(project => {
                        project.name = project.code + ' ' + project.name;
                    })

                    this.filteredProjectList = this.form.get('project')!.valueChanges.pipe(
                        startWith(''),
                        map(value => {
                            const name = typeof value === 'string' ? value : value?.name;
                            return name ? this._filterProyect(name as string) : that.projectList.slice();
                        }),
                    );

                    if ( that.mode == 'update') {
                        that.form.controls['user'].setValue(that.userList.filter(user => user.ID === that.defaults.user_id)[0])
                        // @ts-ignore
                        that.form.controls['project'].setValue(that.projectList.filter(project => project.ID === that.defaults.project_id)[0]);
                    }
                    // @ts-ignore
                    that.notSelectedList = data.data
                    resolve();
                });
            });
        });
    }

    save() {
        // console.log(this.mode)
        if (this.mode === 'create') {
            this.create();
        } else if (this.mode === 'update') {
            this.update();
        }
    }

    create() {
        const that = this;
        console.log(this.mode)
        if (this.form.valid) {

            let daysData = [];
            daysData.push(this.form.value.lunes)
            daysData.push(this.form.value.martes)
            daysData.push(this.form.value.miercoles)
            daysData.push(this.form.value.jueves)
            daysData.push(this.form.value.viernes)
            daysData.push(this.form.value.sabado)
            daysData.push(this.form.value.domingo)

            const group = {
                user_id: this.form.value.user.ID,
                project_id: this.form.value.project.ID,
                days: daysData.join(",")
            }
            this.autoService.create(group).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(group);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }

    }

    update() {
        const that = this;
        if (this.form.valid) {

            let daysData = [];
            daysData.push(this.form.value.lunes)
            daysData.push(this.form.value.martes)
            daysData.push(this.form.value.miercoles)
            daysData.push(this.form.value.jueves)
            daysData.push(this.form.value.viernes)
            daysData.push(this.form.value.sabado)
            daysData.push(this.form.value.domingo)

            const group = {
                user_id: this.form.value.user.ID,
                project_id: this.form.value.project.ID,
                days: daysData.join(",")
            }
            this.autoService.update(group).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(group);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.GRUPOS.SUCCESS : res.GRUPOS.UPDATE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.GRUPOS.ERROR : res.GRUPOS.UPDATE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    displayProjectFn(project: Project) {
        if (project) {
            return project.name;
        }
    }

    onSelectionChange(event: MatAutocompleteSelectedEvent) {

    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        console.log(value);
        if (value) {
            this.selectedUsers.push(null);
        }
        event.chipInput!.clear();
        this.usersCtrl.setValue(null);
    }

    remove(fruit: User): void {
        const index = this.selectedUsers.indexOf(fruit);
        if (index >= 0) {
            this.selectedUsers.splice(index, 1);
        }
    }

    onSelectionUserChange(event: MatAutocompleteSelectedEvent) {
        //console.log('onSelectionChange called', event.option.value);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.selectedUsers.includes(event.option.value)) {
            this.selectedUsers.push(event.option.value);
        }
        this.userInput.nativeElement.value = '';
        this.usersCtrl.setValue(null);
    }

    private _filterUser(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.userList.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    private _filter(value: string): any[] {
        // @ts-ignore
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.firstName.toLowerCase();
        return this.userList.filter(option => option.firstName.toLowerCase().indexOf(filterValue) != -1
            || option.lastName.toLowerCase().indexOf(filterValue) != -1);
    }

    private _filterProyect(value: string): any[] {
        // @ts-ignore
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
        return this.projectList.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    displayUserFn(user: User) {
        if (user) {
            return user.lastName + ' ' + user.firstName;
        }
    }

    resetUser() {

    }
}